import React from "react";

function NotFoundPage() {
  return (
    <div>
       <p>
404 - Page not found.
</p>
    </div>
   
  );
}

export default NotFoundPage;
